import { LoadingOutlined } from "@ant-design/icons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Spin } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Container from "./components/Container";
import CreditCardPage from "./pages/CreditCardPage";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
// import UserPage from "./pages/UserPage";
import UsersPage from "./pages/UsersPage";
import NoTransactionsUserPage from "./pages/NoTransactionsUserPage";
import withAuthentication from "./components/withAuthenticator";
Spin.setDefaultIndicator(
  <LoadingOutlined
    style={{
      animationDuration: ".2s",
    }}
  />
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route element={<Container />}>
            <Route index element={<DashboardPage />} />
            <Route path="users" element={<UsersPage />} />
            {/* <Route path="users/:userID" element={<UserPage />} /> */}
            <Route path="creditcards" element={<CreditCardPage />} />
            <Route path="notransaction" element={<NoTransactionsUserPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default withAuthentication(App);
