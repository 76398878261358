import { timestampToLocalTime } from "anka-endeavour";
import { Form, Input, InputNumber, Select } from "antd";
import DataTable from "../components/DataTable";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import Status from "../components/Status";

function NoTransactionsUserPage() {
  const endeavour = useEndeavour();
  return (
    <DataTable
      uri={`${hostname}/pa-alliance/user/card-no-transaction`}
      filters={[
        <Form.Item label="User" name={"email"}>
          <Input />
        </Form.Item>,
        <Form.Item label="Month">
          <InputNumber value={3} disabled />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "User",
          align: "center",
          render: (data) => {
            console.log(data);
            return data.email;
          },
        },
        {
          title: "Status",
          align: "center",
          render: (data) => {
            return <Status value={data.status} />;
          },
        },

        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Action",
          align: "center",
          render: (data) => {},
        },
      ]}
    />
  );
}

export default NoTransactionsUserPage;
