import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Form, Row, Skeleton, Space, Table } from "antd";
import { useEndeavour } from "../hooks/useEndeavour";
import {
  ClearOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import create from "@ant-design/icons/lib/components/IconFont";

const useElapsedTime = () => {
  const [elapsed, setElapsed] = useState(0);
  const start = useCallback(() => {
    const startTime = performance.now();
    return () => setElapsed(performance.now() - startTime);
  }, []);
  return [elapsed, start];
};

const createSearchParams = (data) => {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([k, v]) => {
    if (typeof v === "string") {
      params.append(k, v);
      return;
    }
    if (Array.isArray(v) && v.length === 2 && v[0]?.$isDayjsObject) {
      params.append(`${k}`, v[0].startOf("day").unix());
      params.append(`${k}`, v[1].endOf("day").unix());
      return;
    }
  });

  return params;
};

const DataTable = ({
  uri,
  uriQuery,
  filters = [],
  filtersInitialValues = {},
  tableTitle,
  tableColumns,
  tableDataSource,
  tableSize = "size",
}) => {
  const endeavour = useEndeavour();
  const [form] = Form.useForm();
  const [paginator, setPaginator] = useState({});
  const [elapsed, startTimer] = useElapsedTime();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState({
    page: 1,
  });

  const query = useQuery({
    queryKey: ["data-table", uri],
    queryFn: () => {
      if (tableDataSource !== undefined) {
        return tableDataSource;
      }
      const end = startTimer();

      const params = createSearchParams(form.getFieldsValue());
      params.append("page", page);
      if (uriQuery) {
        Object.entries(uriQuery).forEach(([k, v]) => {
          if (typeof v === "string") {
            params.append(k, v);
            return;
          }
          if (Array.isArray(v) && v.length === 2 && v[0]?.$isDayjsObject) {
            params.append(`${k}`, v[0].startOf("day").unix());
            params.append(`${k}`, v[1].endOf("day").unix());
            return;
          }
          if (Array.isArray(v)) {
            for (let _v of v) {
              params.append(`${k}`, _v);
            }
            return;
          }
        });
      }

      return fetch(`${uri}?${params.toString()}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setPaginator(res.payload.pagination);
          return res.payload.data;
        })
        .finally(() => {
          end();
        });
    },
  });

  useEffect(() => {
    query.refetch();
  }, [page]);

  const onFinish = (values) => {
    query.refetch();
  };

  return (
    <Row gutter={[endeavour.token.padding * 2, endeavour.token.padding * 2]}>
      {filters.length > 0 && (
        <Col span={24}>
          <Card title="Filters">
            <Form
              layout="vertical"
              initialValues={filtersInitialValues}
              form={form}
              onFinish={onFinish}
            >
              <Row gutter={[8, 8]}>
                {filters.map((item, k) => {
                  return (
                    <Col key={`datable-filters-${k}`} span={4}>
                      {item}
                    </Col>
                  );
                })}
                <Col
                  span={24}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <Space>
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        htmlType="submit"
                      >
                        Search
                      </Button>
                      {/* <Button icon={<DownloadOutlined />}>Export</Button> */}
                      <Button
                        icon={<ClearOutlined />}
                        onClick={() => form.resetFields()}
                      >
                        Reset
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      )}
      <Col span={24}>
        {tableTitle}
        <Table
          size={"small"}
          loading={query.isFetching}
          rowKey={"id"}
          pagination={{
            ...paginator,
            size: "small",
            showSizeChanger: false,
            showTotal: (total) => {
              return `fetched in ${(elapsed / 1000).toFixed(
                4
              )} seconds. Total records: ${total}`;
            },
            position: ["topRight", "bottomRight"],
            onChange: (page) => {
              setPage(page);
            },
          }}
          // bordered
          columns={tableColumns}
          dataSource={tableDataSource ? tableDataSource : query.data}
        />
      </Col>
    </Row>
  );
};

export default DataTable;
