import { timestampToLocalTime } from "anka-endeavour";
import { Form, Input, Select } from "antd";
import DataTable from "../components/DataTable";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import Status from "../components/Status";

function CreditCardPage() {
  const endeavour = useEndeavour();
  return (
    <DataTable
      tableSize="snakk"
      uri={`${hostname}/pa-alliance/user/card`}
      filters={[
        <Form.Item label="User" name={"email"}>
          <Input />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "User",
          align: "center",
          render: (data) => {
            console.log(data);
            return data.email;
          },
        },
        {
          title: "Embossed Name",
          align: "center",
          render: (data) => {
            return data.cardInfo?.embossed_name;
          },
        },
        {
          title: "Binding Status",
          align: "center",
          render: (data) => {
            return (
              <Status
                value={data.cardBinding === false ? "FAILED" : "SUCCESS"}
              />
            );
          },
        },
        {
          title: "Status",
          align: "center",
          render: (data) => {
            return (
              <Status
                value={
                  data.cardInfo?.card_status === false ? "FAILED" : "SUCCESS"
                }
              />
            );
          },
        },
        {
          title: "Network",
          align: "center",
          render: (data) => {
            return data.cardInfo?.network?.toUpperCase();
          },
        },
        {
          title: "Type",
          align: "center",
          render: (data) => {
            return data.cardInfo?.product_name;
          },
        },
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Action",
          align: "center",
          render: (data) => {},
        },
      ]}
    />
  );
}

export default CreditCardPage;
