import { timestampToLocalTime } from "anka-endeavour";
import { Form, Input, Radio, Select } from "antd";
import { useNavigate } from "react-router-dom";
import DataTable from "../components/DataTable";
import Status from "../components/Status";
import { hostname, useEndeavour } from "../hooks/useEndeavour";

function UsersPage() {
  const endeavour = useEndeavour();
  const navigate = useNavigate();
  return (
    <DataTable
      tableSize="small"
      uri={`${hostname}/pa-alliance/users`}
      filters={[
        <Form.Item label="Email" name="email">
          <Input autoFocus />
        </Form.Item>,
        <Form.Item label="E-KYC Status" name="kyc_id">
          <Select
            options={[
              { label: "Verified", value: "1" },
              { label: "Not Verify", value: "0" },
            ]}
          />
        </Form.Item>,
        <Form.Item label="Email Status" name="email_verified">
          <Select
            options={[
              { label: "Verified", value: "1" },
              { label: "Not Verify", value: "0" },
            ]}
          />
        </Form.Item>,
        <Form.Item label="Mobile Status" name="mobile_verified">
          <Select
            options={[
              { label: "Verified", value: "1" },
              { label: "Not Verify", value: "0" },
            ]}
          />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "User",
          align: "center",
          render: (data) => {
            console.log(data);
            return data.email;
          },
        },
        {
          title: "Email Verified",
          align: "center",
          render: (data) => {
            return (
              <Status
                value={data.email_verified === false ? "FAILED" : "SUCCESS"}
              />
            );
          },
        },
        {
          title: "Mobile Verified",
          align: "center",
          render: (data) => {
            return (
              <Status
                value={data.mobile_verified === false ? "FAILED" : "SUCCESS"}
              />
            );
          },
        },
        {
          title: "EKYC",
          align: "center",
          render: (data) => {
            return (
              <Status value={data.kyc_id === null ? "FAILED" : "SUCCESS"} />
            );
          },
        },
        {
          title: "Status",
          align: "center",
          render: (data) => {
            return <Status value={data.status} />;
          },
        },
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Action",
          align: "center",
          render: (data) => {},
        },
      ]}
    />
  );
}

export default UsersPage;
